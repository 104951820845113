import React, { Component } from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
// import PropTypes from 'prop-types';
import '../pages.css';
import '../settingMenu/settingMenu.css';
import moment from 'moment';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import common from '../../common';
import * as actions from '../../../store/action/projectAction';
import { loadObjectStatusRequest } from '../../../store/action/action';
import * as selectors from '../../../store/selector/projectSelector';
import Table from '../../layout/form/Table';
import Pagination from '../../layout/form/Pagination';
import SearchField from '../../layout/form/SearchField';
import TextField from '../../layout/form/TextField';
import DatePicker from '../../layout/form/datePicker';
import ButtonGroup from '../../layout/form/ButtonGroup';
import AddProject from './AddProject';
import Loader from '../../layout/form/Loader';
import ErrorModal from '../../layout/form/errorModal';
import DeleteModal from '../../layout/form/deleteModal';
// import SuccessModal from '../../layout/form/successModal';
// import { loadUserListRequest } from '../../../store/action/usersAction';
import ProjectActivity from './ProjectActivity';
import ProjectAssignments from './ProjectAssignments';
import { makeSelectUserAccessSuccess, makeSelectUserListSuccess } from '../../../store/selector/usersSelector';
import { makeSelectObjectStatusList, makeSelectUOMList } from '../../../store/selector/selector';
import { loadCustomerListRequest, loadCustomerListSuccess } from '../../../store/action/customerAction';
import { loadEmployeeListRequest, loadEmployeeListSuccess } from '../../../store/action/employeeAction';
import SuccessModal from '../../layout/form/successModal';
import ProjectMilestone from './ProjectMilestone';
import { saveActiveRoute } from '../../../store/action/navBarAction';
import ProjectApprover from './ProjectApprove/ProjectApprover';
import { loadUserListRequest, loadUserListSuccess } from '../../../store/action/usersAction';
import Report from './Report';
import { makeSelectTenantBasic } from '../../../store/selector/tenantSelector';
import { loadTenantBasicRequest, loadTenantBasicSuccess } from '../../../store/action/tenantAction';

const dateFormat = sessionStorage.getItem("dateFormat")
var prevDate = new Date();
var today = new Date()
prevDate.setDate(1);
prevDate.setMonth(prevDate.getMonth() - 1);
today.setDate(today.getDate() + 1)

class Project extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFilterFormOpen: false,
            fromDate:moment(prevDate).format(common.dateFormat),
            toDate:moment(today).format(common.dateFormat),
            name: '',
            commonSearch:'',
            pageNumber:1,
            pageSize:20,
            isUpdate: false,
            tab:'projectDetails',
            isChangeTab: false,
            clickedTab:'projectDetails',
            isValidationError: false,
            project:'',
            selectedProjectId: 0,
            isDeleteModalOpen: false,
            selectedRow: {},
            isReportModalOpen: false,
            reportFromDate:moment(prevDate).format(common.dateFormat),
            reportToDate:moment(today).format(common.dateFormat),
            reportResource: 0,
            initial: true,
            timeEntryUOM: '',
        }
    }

    static getDerivedStateFromProps(prevProps,prevState) {
        const {tenantDetails, uomList } = prevProps;
        const { initial } = prevState
        if (initial && tenantDetails && tenantDetails.id > 0 && uomList && uomList.length > 0) {
            const uom = uomList.find(e => e.id === tenantDetails.timeEntryUOMId)
            return {
                initial: false,
                timeEntryUOM: uom.description
            }
        }

        return null;
    }

    componentDidMount (){
        const parameters= {
            pageSize: 9999,
            pageNumber: 1,
            userId: '',
            name: '',
            email: '',
            phoneNumber: '',
            status: '',
            mobile: '',
            city: '',
            commonSearch: '',
            statusId: '',
            firstName: '',
            lastName:  ''
        }
        const id = sessionStorage.getItem("tenantId")
        this.props.dispatch(loadTenantBasicSuccess({}))
        this.props.dispatch(loadTenantBasicRequest(id))
        this.props.dispatch(actions.loadSelectedProjectSuccess(''))
        this.props.dispatch(actions.saveIsProjectModalOpenStatus(false))
        this.props.dispatch(actions.loadProjectListSuccess([]))
        this.props.dispatch(loadCustomerListSuccess([]))
        this.props.dispatch(loadEmployeeListSuccess([]))
        this.props.dispatch(loadEmployeeListRequest(parameters))
        this.props.dispatch(loadCustomerListRequest(parameters))
        this.props.dispatch(actions.loadProjectAllocationListRequest())
        this.getProjectList()
        this.props.dispatch(loadObjectStatusRequest(common.objectId.project));
        this.props.dispatch(actions.loadProjectTypeRequest())
        this.props.dispatch(actions.loadProjectBillingTypeRequest())
        this.props.dispatch(actions.loadProjectTimeInputTypeRequest())
        this.props.dispatch(loadUserListSuccess([]))
        this.props.dispatch(loadUserListRequest(parameters))
    }

    componentDidUpdate (prevProps, prevState){
        if((this.state.pageNumber !== prevState.pageNumber) || (this.state.pageSize !== prevState.pageSize)){
            this.getProjectList()
        }

        if (this.props.projectSuccess !== null){
            setTimeout(
                () => this.successModalClose(), 
                1000
            );
        }
    }

    getProjectList = () => {
        const parameters= {
            pageSize:this.state.isFilterFormOpen ? 20 : this.state.pageSize,
            pageNumber:this.state.isFilterFormOpen ? 1 : this.state.pageNumber,
            name:this.state.name,
            toDate:this.state.toDate,
            fromDate:this.state.fromDate,
            commonSearch: this.state.commonSearch
        }
        this.props.dispatch(actions.loadProjectListRequest(parameters))
    }

    onPressKeySearch = (value) => {
        const parameters= {
            pageSize:this.state.isFilterFormOpen ? 20 : this.state.pageSize,
            pageNumber:this.state.isFilterFormOpen ? 1 : this.state.pageNumber,
            name:this.state.name,
            toDate:this.state.toDate,
            fromDate:this.state.fromDate,
            commonSearch: value
        }
        if(this.state.isFilterFormOpen){
            this.setState({
                pageNumber:1,
                pageSize:20
            })
        }
        this.setState({
            commonSearch:value
        })
        this.props.dispatch(actions.loadProjectListRequest(parameters))
    }

    handleChangeSearchValue = (e)=> {
        if(e.target.value === "" || e.target.value === null){
            this.handleRefresh()
        }
        this.setState({
            commonSearch: e.target.value,
            isFilterFormOpen:false,
            fromDate:moment(prevDate).format(common.dateFormat),
            toDate:moment(today).format(common.dateFormat),
            name: '',
            pageSize:20,
            pageNumber:1,
        })
    }

    setDateFormat = (value) => {
        const date = moment(value).format(dateFormat? dateFormat : common.dateFormat);
        return date
    }

    setStatusDescription = (data) => {
        const { objectStatusList } = this.props;
        if(objectStatusList && objectStatusList.length && objectStatusList.length !== 0){
          const desc = objectStatusList.find(select =>  select.id === data.status)
          return (desc && desc.description ? desc.description : '')
        }
    }

    refreshTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Refresh
        </Tooltip>
    );

    addTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Add new project
        </Tooltip>
    );

    filterTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Filter
        </Tooltip>
    );

    handleModal = (isUpdate,data) => {
        if(isUpdate){
            this.props.dispatch(actions.loadSelectedProjectSuccess(''))
            this.props.dispatch(actions.loadSelectedProjectRequest(data.id))
        }
        this.props.dispatch(actions.saveIsProjectModalOpenStatus(true))
        this.setState({
            isUpdate: isUpdate
        })
    }

    handleClose = () => {
        this.setState({
            isUpdate: false,
            tab:'projectDetails',
            isChangeTab: false,
            clickedTab:'projectDetails',
            selectedProjectId:0
        })
        this.props.dispatch(actions.loadSelectedProjectSuccess(''))
        this.props.dispatch(actions.saveIsProjectModalOpenStatus(false))
        this.getProjectList()
    }

    handleDeleteConfirm = (data) => {
        this.setState({isDeleteModalOpen:true, selectedRow:data})
    }

    handleDeleteModalClose = () =>{
        this.setState({isDeleteModalOpen:false, selectedRow:{}})
    }

    handleDeleteProject = () =>{
        this.handleDeleteModalClose()
        this.props.dispatch(actions.sendDeleteProjectRequest(this.state.selectedRow.id))
    }

    handleReport = (data) => {
        this.props.dispatch(actions.loadReportByProjectSuccess([]))
        this.setState({
            isReportModalOpen: true,
            reportFromDate: data.actualStartDate,
            selectedRow: data
        })
    }

    handleReportModalClose = () => {
        this.setState({
            isReportModalOpen: false,
            selectedRow: {},
            reportToDate:moment(today).format(common.dateFormat),
            reportResource: 0
        })
    }

    handleGetReport = () => {
        this.props.dispatch(actions.loadReportByProjectSuccess([]))
        const parameters = {
            projectId: this.state.selectedRow.id,
            fromDate: this.state.reportFromDate,
            toDate: this.state.reportToDate,
            resource: this.state.reportResource
        }
        this.props.dispatch(actions.loadReportByProjectRequest(parameters))
    }

    handleSelect = (value, id) => {
        this.setState({
            [id]: value
        })
    }

    handleSaveAddNew = (details) => {
        if(details.projectName === '' || details.projectName === null){
            this.props.dispatch(actions.loadProjectListError('Please enter valid project name'))
            return
        }
        if(details.projectCode === '' || details.projectCode === null){
            this.props.dispatch(actions.loadProjectListError('Please enter valid project code'))
            return
        }
        if(details.projectTypeId === null || details.projectTypeId === ''){
            this.props.dispatch(actions.loadProjectListError('Please select project type'))
            return
        }
        if(details.projectBillingTypeId === null || details.projectBillingTypeId === ''){

            this.props.dispatch(actions.loadProjectListError('Please select project billing type'))
            return
        }
        const parameters= {
            id: details.id ? details.id : 0,
            projectName: details.projectName,
            projectCode: details.projectCode,
            planStartDate: details.planStartDate,
            planEndDate: details.planEndDate,
            actualStartDate: details.actualStartDate,
            actualEndDate: details.actualEndDate,
            projectManagerId: details.projectManagerId,
            status: details.status,
            customerId: details.customerId,
            notes: details.notes,
            projectBillingTypeId: details.projectBillingTypeId,
            projectTimeInputTypeId: details.projectTimeInputTypeId,
            projectTypeId: details.projectTypeId,
            purchaseOrderNo: details.purchaseOrderNo,
            ProjectActivities: [],
            ProjectAssignments: [],
            ProjectMilestones: []
        }
        if(this.state.isUpdate){
            // this.setState({
            //     selectedProjectId: details.id
            // })
            this.props.dispatch(actions.sendUpdateProjectRequest(parameters))
        }else 
            this.props.dispatch(actions.sendAddNewProjectRequest(parameters))
    }

    handleSaveProject = (details) => {
        if(details.projectName === '' || details.projectName === null){
            this.props.dispatch(actions.loadProjectListError('Please enter valid project name'))
            return
        }
        if(details.projectCode === '' || details.projectCode === null){
            this.props.dispatch(actions.loadProjectListError('Please enter valid project code'))
            return
        }
        if(details.projectTypeId === null || details.projectTypeId === ''){
            this.props.dispatch(actions.loadProjectListError('Please select project type'))
            return
        }
        if(details.projectBillingTypeId === null || details.projectBillingTypeId === ''){

            this.props.dispatch(actions.loadProjectListError('Please select project billing type'))
            return
        }
        const parameters= {
            id: details.id ? details.id : 0,
            projectName: details.projectName,
            projectCode: details.projectCode,
            planStartDate: details.planStartDate,
            planEndDate: details.planEndDate,
            actualStartDate: details.actualStartDate,
            actualEndDate: details.actualEndDate,
            projectManagerId: details.projectManagerId,
            status: details.status,
            customerId: details.customerId,
            notes: details.notes,
            projectBillingTypeId: details.projectBillingTypeId,
            projectTimeInputTypeId: details.projectTimeInputTypeId,
            projectTypeId: details.projectTypeId,
            purchaseOrderNo: details.purchaseOrderNo,
            ProjectActivities: [],
            ProjectAssignments: [],
            ProjectMilestones: []
        }
        if(this.state.isUpdate){
            // this.setState({
            //     selectedProjectId: details.id
            // })
            this.props.dispatch(actions.sendUpdateProjectRequest(parameters))
        }else 
            this.props.dispatch(actions.sendAddNewProjectRequest(parameters))
        this.handleClose()
    }

    handleSaveProjectMilestones = (details) => {
        const { project } = this.state;
        const { selectedProject } = this.props;
        const tempItemList = details.tempActivityList.filter(data => (data.operation === "D"))
        const projectMilestones = details.milestoneList.concat(tempItemList)
        const parameters= {
            id: selectedProject.id ? selectedProject.id : 0,
            projectName: project.projectName ? project.projectName : selectedProject.projectName,
            projectCode: project.projectCode ? project.projectCode : selectedProject.projectCode,
            planStartDate: project.planStartDate ? project.planStartDate : selectedProject.planStartDate,
            planEndDate: project.planEndDate ? project.planEndDate : selectedProject.planEndDate,
            actualStartDate: project.actualStartDate ? project.actualStartDate : selectedProject.actualStartDate,
            actualEndDate: project.actualEndDate ? project.actualEndDate : selectedProject.actualEndDate,
            projectManagerId: project.projectManagerId ? project.projectManagerId : selectedProject.projectManagerId,
            status: project.status ? project.status : selectedProject.status,
            notes: project.notes ? project.notes : selectedProject.notes,
            projectBillingTypeId: project.projectBillingTypeId ? project.projectBillingTypeId : selectedProject.projectBillingTypeId,
            projectTimeInputTypeId: project.projectTimeInputTypeId ? project.projectTimeInputTypeId : selectedProject.projectTimeInputTypeId,
            projectTypeId: project.projectTypeId ? project.projectTypeId : selectedProject.projectTypeId,
            purchaseOrderNo: project.purchaseOrderNo ? project.purchaseOrderNo : selectedProject.purchaseOrderNo,
            customerId: project.customerId ? project.customerId : selectedProject.customerId,
            ProjectActivities: [],
            ProjectAssignments: [],
            ProjectMilestones: projectMilestones
        }
        // this.setState({
        //     selectedProjectId: project.id
        // })
        this.props.dispatch(actions.sendUpdateProjectActivityRequest(parameters))
        this.props.dispatch(actions.loadSelectedProjectSuccess(''))
    }

    handleSaveProjectApprovers = (details) => {
        const { project } = this.state;
        const { selectedProject } = this.props;
        const projectApprovers = []
        const element = {
            userId: details.userId,
            operation: details.operation,
            id: details.id,
            approvalSequence: details.sequence
        }
        projectApprovers.push(element);
        const parameters= {
            id: selectedProject.id ? selectedProject.id : 0,
            projectName: project.projectName ? project.projectName : selectedProject.projectName,
            projectCode: project.projectCode ? project.projectCode : selectedProject.projectCode,
            planStartDate: project.planStartDate ? project.planStartDate : selectedProject.planStartDate,
            planEndDate: project.planEndDate ? project.planEndDate : selectedProject.planEndDate,
            actualStartDate: project.actualStartDate ? project.actualStartDate : selectedProject.actualStartDate,
            actualEndDate: project.actualEndDate ? project.actualEndDate : selectedProject.actualEndDate,
            projectManagerId: project.projectManagerId ? project.projectManagerId : selectedProject.projectManagerId,
            status: project.status ? project.status : selectedProject.status,
            notes: project.notes ? project.notes : selectedProject.notes,
            projectBillingTypeId: project.projectBillingTypeId ? project.projectBillingTypeId : selectedProject.projectBillingTypeId,
            projectTimeInputTypeId: project.projectTimeInputTypeId ? project.projectTimeInputTypeId : selectedProject.projectTimeInputTypeId,
            projectTypeId: project.projectTypeId ? project.projectTypeId : selectedProject.projectTypeId,
            purchaseOrderNo: project.purchaseOrderNo ? project.purchaseOrderNo : selectedProject.purchaseOrderNo,
            customerId: project.customerId ? project.customerId : selectedProject.customerId,
            ProjectActivities: [],
            ProjectAssignments: [],
            ProjectMilestones: [],
            ProjectApprovers: projectApprovers
        }
        this.props.dispatch(actions.sendUpdateProjectActivityRequest(parameters))
        this.props.dispatch(actions.loadSelectedProjectSuccess(''))
    }

    handleSaveProjectActivities = (details) => {
        const { project } = this.state;
        const { selectedProject } = this.props;
        const tempItemList = details.tempActivityList.filter(data => (data.operation === "D"))
        const projectActivities = details.activityList.concat(tempItemList)
        const parameters= {
            id: selectedProject.id ? selectedProject.id : 0,
            projectName: project.projectName ? project.projectName : selectedProject.projectName,
            projectCode: project.projectCode ? project.projectCode : selectedProject.projectCode,
            planStartDate: project.planStartDate ? project.planStartDate : selectedProject.planStartDate,
            planEndDate: project.planEndDate ? project.planEndDate : selectedProject.planEndDate,
            actualStartDate: project.actualStartDate ? project.actualStartDate : selectedProject.actualStartDate,
            actualEndDate: project.actualEndDate ? project.actualEndDate : selectedProject.actualEndDate,
            projectManagerId: project.projectManagerId ? project.projectManagerId : selectedProject.projectManagerId,
            status: project.status ? project.status : selectedProject.status,
            notes: project.notes ? project.notes : selectedProject.notes,
            projectBillingTypeId: project.projectBillingTypeId ? project.projectBillingTypeId : selectedProject.projectBillingTypeId,
            projectTimeInputTypeId: project.projectTimeInputTypeId ? project.projectTimeInputTypeId : selectedProject.projectTimeInputTypeId,
            projectTypeId: project.projectTypeId ? project.projectTypeId : selectedProject.projectTypeId,
            purchaseOrderNo: project.purchaseOrderNo ? project.purchaseOrderNo : selectedProject.purchaseOrderNo,
            customerId: project.customerId ? project.customerId : selectedProject.customerId,
            ProjectActivities: projectActivities,
            ProjectAssignments: [],
            ProjectMilestones: []
        }
        // this.setState({
        //     selectedProjectId: project.id
        // })
        this.props.dispatch(actions.sendUpdateProjectActivityRequest(parameters))
        this.props.dispatch(actions.loadSelectedProjectSuccess(''))
    }

    
    handleSaveImportProjectActivities = (details) => {
        const { selectedProject } = this.props;
        const tempItemList = details.tempActivityList.filter(data => (data.operation === "D"))
        const projectActivities = details.activityList.concat(tempItemList)
        const parameters= {
            id: selectedProject.id ? selectedProject.id : 0,
            ProjectActivities: projectActivities,
        }
        this.props.dispatch(actions.sendImportProjectActivityRequest(parameters))
        this.props.dispatch(actions.loadSelectedProjectSuccess(''))
    }

    handleSaveProjectAssignment = (details) => {
        const { project } = this.state;
        const { selectedProject } = this.props;
        const tempItemList = details.tempAssignmentList.filter(data => (data.operation === "D"))
        const projectAssignment  = details.assignmentList.concat(tempItemList)
        const parameters= {
            id: selectedProject.id ? selectedProject.id : 0,
            projectName: project.projectName ? project.projectName : selectedProject.projectName,
            projectCode: project.projectCode ? project.projectCode : selectedProject.projectCode,
            planStartDate: project.planStartDate ? project.planStartDate : selectedProject.planStartDate,
            planEndDate: project.planEndDate ? project.planEndDate : selectedProject.planEndDate,
            actualStartDate: project.actualStartDate ? project.actualStartDate : selectedProject.actualStartDate,
            actualEndDate: project.actualEndDate ? project.actualEndDate : selectedProject.actualEndDate,
            projectManagerId: project.projectManagerId ? project.projectManagerId : selectedProject.projectManagerId,
            status: project.status ? project.status : selectedProject.status,
            notes: project.notes ? project.notes : selectedProject.notes,
            projectBillingTypeId: project.projectBillingTypeId ? project.projectBillingTypeId : selectedProject.projectBillingTypeId,
            projectTimeInputTypeId: project.projectTimeInputTypeId ? project.projectTimeInputTypeId : selectedProject.projectTimeInputTypeId,
            projectTypeId: project.projectTypeId ? project.projectTypeId : selectedProject.projectTypeId,
            purchaseOrderNo: project.purchaseOrderNo ? project.purchaseOrderNo : selectedProject.purchaseOrderNo,
            ProjectActivities: [],
            ProjectMilestones: [],
            ProjectAssignments: projectAssignment,
            customerId: project.customerId ? project.customerId : selectedProject.customerId,
        }
        // this.setState({
        //     selectedProjectId: project.id
        // })
        this.props.dispatch(actions.sendUpdateProjectAssignmentRequest(parameters))
        this.props.dispatch(actions.loadSelectedProjectSuccess(''))
    }

    handleFilterForm = () => {
        this.setState({
            isFilterFormOpen:!this.state.isFilterFormOpen,
            name:this.state.name,
            toDate:this.state.toDate,
            fromDate:this.state.fromDate,
            commonSearch:''
        })
    }

    handleRefresh = () =>{
        this.setState({
            isFilterFormOpen: false,
            fromDate:moment(prevDate).format(common.dateFormat),
            toDate:moment(today).format(common.dateFormat),
            name: '',
            commonSearch:'',
            pageNumber:1,
            pageSize:20,
        })

        const parameters = {
            fromDate:moment(prevDate).format(common.dateFormat),
            toDate:moment(today).format(common.dateFormat),
            name: '',
            commonSearch:'',
            pageNumber:1,
            pageSize:20,
        }
        this.props.dispatch(actions.loadProjectListRequest(parameters))
    }

    handleFilter = () =>{
        this.getProjectList()
        this.handleFilterForm()
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleDateChange = (date, id) => {
        this.setState({
            [id]: moment(date).format(common.dateFormat)
        })
    }

    handleTab = (value,details) => {
        const { selectedProject } = this.props;
        const { selectedProjectId } = this.state;
        if(details.projectName === '' || details.projectName === null){
            this.props.dispatch(actions.loadProjectListError('Please enter valid project name'))
            return
        }
        if(details.projectCode === '' || details.projectCode === null){
            this.props.dispatch(actions.loadProjectListError('Please enter valid project code'))
            return
        }
        this.props.dispatch(actions.sendProjectSuccess(null))
        this.props.dispatch(actions.sendProjectActivitySuccess(null))
        this.props.dispatch(actions.sendProjectAssignmentSuccess(null))
        if(this.state.tab === 'projectDetails' && details.isChangeData){
            this.props.dispatch(actions.loadSelectedProjectSuccess(''))
            if((selectedProject && selectedProject.id > 0) || selectedProjectId > 0)
            {
                this.props.dispatch(actions.loadSelectedProjectRequest(selectedProject.id ? selectedProject.id :selectedProjectId ))
            }
            this.handleSaveAddNew(details)
        }
        else if(this.state.tab === 'projectMilestone' && details.isChangeData){
            this.props.dispatch(actions.loadSelectedProjectSuccess(''))
            this.props.dispatch(actions.loadSelectedProjectRequest(selectedProject.id ? selectedProject.id :selectedProjectId ))
            this.handleSaveProjectMilestones(details)
        }
        else if(this.state.tab === 'projectActivities' && details.isChangeData){
            this.props.dispatch(actions.loadSelectedProjectSuccess(''))
            this.props.dispatch(actions.loadSelectedProjectRequest(selectedProject.id ? selectedProject.id :selectedProjectId ))
            this.handleSaveProjectActivities(details)
        }
        else if(this.state.tab === 'projectAssignments' && details.isChangeData){
            this.props.dispatch(actions.loadSelectedProjectSuccess(''))
            this.props.dispatch(actions.loadSelectedProjectRequest(selectedProject.id ? selectedProject.id :selectedProjectId ))
            this.handleSaveProjectAssignment(details)
        }

        if(value === 'projectActivities' || value === 'projectAssignments' || value === 'projectDetails'|| value === 'projectMilestone' || value === 'projectApprovers' ){
            this.setState({
                project: details,
                tab: value,
                selectedProjectId: selectedProject.id ?  selectedProject.id : 0,
                isUpdate: true
            })
        }
    }

    getNextPage = () => {
        this.setState({
            pageNumber:this.state.pageNumber + 1
        })
    }

    getPrevPage = () => {
        this.setState({
            pageNumber:this.state.pageNumber - 1
        })
    }

    setClickedPage = (page) => {
        this.setState({
            pageNumber:page
        })
    }

    setPageSize = (size) => {
        this.setState({
            pageSize:size,
            pageNumber:1
        })
    }

    errorModalClose = () => {
        this.setState({isValidationError:false, errorMassage:''})
        this.props.dispatch(actions.loadProjectListError(null))
    }

    successModalClose = () => {
        const { selectedProjectId } = this.state;
        if(selectedProjectId > 0){
            this.props.dispatch(actions.loadSelectedProjectRequest(selectedProjectId))
        }
        this.props.dispatch(actions.sendProjectSuccess(null))
        this.setState({
            isUpdate:true
        })
        this.getProjectList()
    }

    handleAddInvoice = (list) => {
        const { selectedProject } = this.props;
        const details = selectedProject
        details.ProjectMilestones = list
        this.props.dispatch(actions.loadSelectedProjectSuccess(details))
        this.props.history.push('/invoice')
        this.props.dispatch(saveActiveRoute('#/invoice'))
    }

    handleCreateInvoice = (element) => {
        const list = []
        list.push(element)
        const { selectedProject } = this.props;
        const details = selectedProject
        details.ProjectMilestones = list
        this.props.dispatch(actions.loadSelectedProjectSuccess(details))
        this.props.history.push('/invoice')
        this.props.dispatch(saveActiveRoute('#/invoice'))
    }


    render() {
        const { 
            projectList,
            isProjectModalOpen,
            isProjectListLoading,
            projectListError,
            userAccess,
            projectSuccess,
            projectTypeList,
            projectBillingTypeList,
            projectTimeInputTypeList,
            userList,
            projectReport
        } = this.props;
        const { 
            isFilterFormOpen,
            tab,
            isUpdate,
            project,
            selectedProjectId,
            isDeleteModalOpen,
            selectedRow,
            isReportModalOpen,
            reportFromDate,
            reportToDate,
            reportResource,
            timeEntryUOM
        } = this.state;
        const columns = [{
            Header: 'Project Code',
            accessor: 'projectCode',
            width: "10%",
          },{
            Header: 'Project Name',
            accessor: 'projectName',
          },{
            Header: 'Customer Name',
            accessor: 'customerName',
          },{
            Header: 'Plan Start Date',
            accessor: 'planStartDate',
            width: "15%",
            Cell:((row)=>(
                <div >
                  {row.row.original.planStartDate === '1/1/0001' ? '' : this.setDateFormat(row.row.original.planStartDate)}
                </div>
              )),
          },{
            Header: 'Plan End Date',
            accessor: 'planEndDate',
            width: "15%",
            Cell:((row)=>(
                <div >
                  {row.row.original.planEndDate === '1/1/0001' ? '' : this.setDateFormat(row.row.original.planEndDate)}
                </div>
              )),
          },{
            Header: 'Status',
            accessor: 'statusId',
            width: "10%",
            Cell:((row)=>(
                <div style={{padding:'2px'}}>
                    {row.row.original.status === "10" ? 
                        <div style={{backgroundColor:'#ebd936',color:'white',borderRadius:'20px',padding:'2px',paddingLeft:'10px',paddingRight:'10px'}}>{this.setStatusDescription(row.row.original)}</div> :
                        row.row.original.status === "20" ? 
                        <div style={{backgroundColor:'#36eb69',color:'white',borderRadius:'20px',padding:'2px',paddingLeft:'10px',paddingRight:'10px'}}>{this.setStatusDescription(row.row.original)}</div> :
                        <div style={{backgroundColor:'#ff6384',color:'white',borderRadius:'20px',padding:'2px',paddingLeft:'10px',paddingRight:'10px'}}>{this.setStatusDescription(row.row.original)}</div>
                    }
                </div>
              )),
          },{
            Header:'Action',
            width: "8%",
            Cell:((row)=>(
                <div>
                    {/* <Button */}
                    {<span style={{display:'flex',justifyContent:'space-evenly'}}>
                      <span onClick={()=>this.handleModal(true,row.row.original)}><ui5-icon class="samples-margin" name="display" id="table-icon"></ui5-icon></span>  
                      {/* <span onClick={userAccess && userAccess.deleteProject ? ()=>this.handleDeleteConfirm(row.row.original) : ''}><ui5-icon class="samples-margin" name="delete" id={userAccess && userAccess.deleteProject ? "table-icon": "table-icon-disabled"}></ui5-icon></span>   */}
                      <span className="dropdown" style={{ float: 'left' }}>
                            <ui5-icon class="samples-margin" name="menu" id="table-icon"></ui5-icon>
                            <div className="dropdown-content">
                                {userAccess && userAccess.deleteProject ? <li onClick={() => this.handleDeleteConfirm(row.row.original)}><ui5-icon class="samples-margin" name="delete" id="action-icon"></ui5-icon> Delete</li> : ''}
                                <li onClick={() => this.handleReport(row.row.original)}><ui5-icon class="samples-margin" name="monitor-payments" id="action-icon"></ui5-icon> Report</li>
                            </div>
                        </span>
                    </span>}
                    {/* /> */}
                </div>
            )),
            accessor:'action'
          }]

          const reportColumns = [{
            Header: 'Task',
            accessor: 'description',
          },{
            Header: 'Resource',
            accessor: 'resource',
            Cell: ((row) => (
                <div>
                    {row.row.original.firstName + " " + row.row.original.lastName}
                </div>
            ))
          },{
            Header: () => (<div style={{ textAlign: "right" }}>Time</div>),
            accessor: 'time',
            width: "10%",
            Cell: ((row) => (
                <div style={{
                    textAlign: "right"
                }}>
                    {row.row.original.time ? parseFloat(row.row.original.time).toFixed(2) : "0.00"}
                </div>
            ))
          },{
            Header: 'UOM',
            accessor: 'uom',
            Cell: ((row) => (
                <div >
                    {timeEntryUOM}
                </div>
            )),
            width: "5%",
        }

          ]
        return (
          <div>
            {(isProjectListLoading ) &&
                <Loader show={isProjectListLoading}/> }

            {(this.state.isValidationError || projectListError !== null ) && 
                <ErrorModal
                    show={true} 
                    massage={this.state.errorMassage ? this.state.errorMassage : projectListError}
                    handleClose={this.errorModalClose}
                />}

            {isDeleteModalOpen && 
                    <DeleteModal
                        show={isDeleteModalOpen}
                        handleDeleteModalClose={this.handleDeleteModalClose}
                        handleDelete={this.handleDeleteProject}								
                        title={selectedRow.projectName}
                    />}
            {(projectSuccess) && 
                <SuccessModal
                    show={true} 
                    massage={projectSuccess}
                    handleClose={this.successModalClose}
                />}
                {isReportModalOpen ?
                    <Report
                        isReportModalOpen={isReportModalOpen}
                        handleModalClose={this.handleReportModalClose}
                        reportFromDate={reportFromDate}
                        reportToDate={reportToDate}
                        title={selectedRow?.projectName}
                        userList={userList?.result}
                        reportResource={reportResource}
                        columns={reportColumns}
                        handleReport={this.handleGetReport}
                        projectReport={projectReport}
                        handleDateChange={this.handleDateChange}
                        handleSelect={this.handleSelect}
                    />
                    : ''
                }
            {isProjectModalOpen ? 
                <>
                    {tab === "projectDetails" && 
                        <AddProject 
                            handleTab={this.handleTab} 
                            handleClose={this.handleClose}
                            // handleSaveAddNew={this.handleSaveAddNew}
                            isUpdate={isUpdate}
                            selectedProjectId={selectedProjectId}
                            modifyProject={userAccess && userAccess.modifyProject}
                            handleSaveProject={this.handleSaveProject}
                            projectBillingTypeList={projectBillingTypeList}
                            projectTypeList={projectTypeList}
                            projectTimeInputTypeList={projectTimeInputTypeList}
                        /> 
                    }
                    {tab === "projectActivities" && 
                        <ProjectActivity 
                            handleTab={this.handleTab} 
                            handleClose={this.handleClose}
                            handleSaveAddNew={this.handleSaveProjectActivities}
                            isUpdate={isUpdate}
                            project={project}
                            selectedProjectId={selectedProjectId}
                            modifyProject={userAccess && userAccess.modifyProject}
                            handleSaveImportProjectActivities={this.handleSaveImportProjectActivities}
                        /> 
                    }
                    {tab === "projectAssignments" && 
                        <ProjectAssignments 
                            handleTab={this.handleTab} 
                            handleClose={this.handleClose}
                            handleSaveAddNew={this.handleSaveProjectAssignment}
                            isUpdate={isUpdate}
                            project={project}
                            selectedProjectId={selectedProjectId}
                            modifyProject={userAccess && userAccess.modifyProject}
                        /> 
                    }
                    {tab === "projectMilestone" && 
                        <ProjectMilestone 
                            handleTab={this.handleTab} 
                            handleClose={this.handleClose}
                            handleSaveAddNew={this.handleSaveProjectMilestones}
                            isUpdate={isUpdate}
                            project={project}
                            selectedProjectId={selectedProjectId}
                            modifyProject={userAccess && userAccess.modifyProject}
                            handleAddInvoice={this.handleAddInvoice}
                            handleCreateInvoice={this.handleCreateInvoice}
                        /> 
                    }
                    {tab === "projectApprovers" && 
                        <ProjectApprover 
                            handleTab={this.handleTab} 
                            handleClose={this.handleClose}
                            handleSaveAddNew={this.handleSaveProjectApprovers}
                            isUpdate={isUpdate}
                            project={project}
                            selectedProjectId={selectedProjectId}
                            modifyProject={userAccess && userAccess.modifyProject}
                            handleAddInvoice={this.handleAddInvoice}
                            handleCreateInvoice={this.handleCreateInvoice}
                        /> 
                    }
                </>
                    :
            <>
            <div className="content-header">
                <div className="header-pages">
                    <ui5-icon class="samples-margin" name="project-definition-triangle" id="header-icon"></ui5-icon><span>PROJECTS</span>
                </div>
            </div>
            <div className="content-body">
                <div style={{display:'flex', justifyContent:'space-between', margin:'5px 0'}}>
                    <div style={{display:'flex'}}>
                        <div>
                            <h5 style={{marginLeft:'5px'}}>Project List</h5>
                        </div>
                        <div style={{marginLeft:'20px'}}>
                            <SearchField
                                onClickSearch={this.getProjectList}
                                controlId="commonSearch"
                                onChange={this.handleChangeSearchValue}
                                defaultValue={this.state.commonSearch}
                                onPressKeySearch={this.onPressKeySearch}
                            />
                        </div>
                    </div>
                    <div className="header-btn">
                        <div style={{display:'flex'}}>
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={this.refreshTooltip}
                            >
                            <span className="header-icon" onClick={() => this.handleRefresh()}>
                                <ui5-icon class="samples-margin" name="refresh" id="header-icon"></ui5-icon>
                            </span>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={this.addTooltip}
                            >
                                <span className={userAccess && userAccess.createProject ? "header-icon" : "header-icon-disabled"}  onClick={userAccess && userAccess.createProject ? () => this.handleModal(false) : ''}>
                                    <ui5-icon class="samples-margin" name="add" id="header-icon"></ui5-icon>
                                </span>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={this.filterTooltip}
                            >
                                <span className="header-icon"  onClick={() => this.handleFilterForm()}>
                                    <ui5-icon class="samples-margin" name="filter" id="header-icon"></ui5-icon>
                                </span>
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>
                {isFilterFormOpen && 
                    <div className="filter-form">
                        <div className="filter-header">
                            <h5>Filter Form</h5>
                            <div className="btn-close" onClick={this.handleRefresh} >
                                <ui5-icon class="samples-margin" name="decline" id="btn-icon"></ui5-icon>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <TextField
                                    text="Project name"
                                    controlId="name"
                                    type="text"
                                    onChange={this.handleChange}
                                    defaultValue={this.state.name}
                                />
                            </div>
                            <div className="col-sm">
                                <DatePicker
                                    text="From date"
                                    controlId="fromDate"
                                    onChange={(e) => this.handleDateChange(e,"fromDate")}
                                    value={this.state.fromDate === null ? '' : new Date(this.state.fromDate)}
                                />
                            </div>
                            <div className="col-sm">
                                <DatePicker
                                    text="To date"
                                    controlId="toDate"
                                    onChange={(e) => this.handleDateChange(e,"toDate")}
                                    value={this.state.fromDate === null ? '' : new Date(this.state.toDate)}
                                />
                            </div>
                        </div>
                        <div className="filter-btns">
                            <ButtonGroup
                                primaryBtnClick={this.handleFilter}
                                primaryBtnIcon='search'
                                primaryBtnText='Search'
                                primaryBtnVariant="outline-primary" 
                                secondaryBtnClick={this.handleRefresh}
                                secondaryBtnIcon='clear-filter'
                                secondaryBtnText='Clear'
                                secondaryBtnVariant="outline-primary" 
                            />
                        </div>
                    </div>}
                <div className="table-content">
                    <Table
                        columns={columns}
                        data={projectList.result && projectList.result.length ? projectList.result : []}                   
                    />
                </div>
                <Pagination
                    currentPage={projectList.page ? projectList.page :this.state.pageNumber}
                    getNextPage={this.getNextPage}
                    getPrevPage={this.getPrevPage}
                    totalPage = {projectList.totalPages}
                    setClickedPage= {this.setClickedPage}
                    currentPageSize={this.state.pageSize}
                    setPageSize={this.setPageSize}
                />
            </div>
            </>
            }
          </div>
        )
    }
}

Project.propTypes = {

}
  
const mapStateToProps = createStructuredSelector({
    projectList: selectors.makeSelectProjectList(),
    isProjectModalOpen : selectors.makeSelectProjectPopupOpen(),
    isProjectListLoading: selectors.makeSelectProjectListLoading(),
    projectListError: selectors.makeSelectProjectListError(),
    projectSuccess: selectors.makeSelectProjectSuccess(),
    selectedProject: selectors.makeSelectSelectedProjectSuccess(),
    userAccess: makeSelectUserAccessSuccess(),
    objectStatusList:makeSelectObjectStatusList(),
    projectTypeList: selectors.makeSelectProjectTypeListSuccess(),
    projectBillingTypeList: selectors.makeSelectProjectBillingTypeListSuccess(),
    projectTimeInputTypeList: selectors.makeSelectProjectTimeInputTypeListSuccess(),
    userList: makeSelectUserListSuccess(),
    projectReport: selectors.makeSelectProjectReportSuccess(),
    tenantDetails: makeSelectTenantBasic(),
    uomList: makeSelectUOMList(),
});
  
function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
}
  
const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
);
  
  
export default (compose(withConnect)(Project));
